<template>
  <v-layout column fill-height>
    <v-container :class="['px-6 py-0', fullHeight ? 'absolute-container' : 'container--fluid']">
      <v-layout column :class="fullHeight ? 'fill-height' : ''">
        <v-layout class="pb-6 mt-6" v-if="title">
          <v-layout style="min-height: 41px !important" align-center>
            <v-flex @click.prevent="handleBack" shrink v-if="back"
              style="cursor: pointer; font-family: b-icons; font-size: 20px; margin-right: 14px"></v-flex>
            <DefaultTitle>{{ title }}</DefaultTitle>
          </v-layout>
        </v-layout>
        <v-layout justify-center :class="[
          this.$vuetify.breakpoint.smAndUp ? '' : 'wrap czat-order',
          fullHeight ? 'fill-height' : '',
        ]">
          <v-flex :class="fullHeight ? 'fill-height' : ''">
            <v-card v-if="showMainContainer" :style="{backgroundColor: backgroundColor}"
              :class="[!hideShadow && 'default-shadow', fullHeight ? 'fill-height' : '']">
              <v-card-text :class="[
                fullHeight ? 'fill-height' : '',
                contentPadding ? '' : customContentPadding,
              ]">
                <slot name="subview-content"></slot>
                <v-layout align-center justify-end :class="[
                  buttons.length && 'mt-2',
                  this.$vuetify.breakpoint.mdAndUp ? 'mx-2' : '',
                ]">
                  <slot name="customButtons"></slot>
                  <DefaultButton v-for="item in buttons" :key="item.name" :bgColor="item.color" :loading="item.loading"
                    @click.prevent="item.action">{{ item.name }}</DefaultButton>
                  <slot name="additional-buttons"></slot>
                </v-layout>
              </v-card-text>
            </v-card>
            <slot name="subview-content-documents"></slot>
            <slot name="subview-content-legal"></slot>
          </v-flex>
          <v-flex shrink>
            <slot name="subview-right-content"></slot>
          </v-flex>
        </v-layout>
        <slot name="middle-subview-content"></slot>
        <v-flex v-show="showAdditionalContent">
          <v-card :class="[
            'default-shadow mt-6',
            this.$vuetify.breakpoint.mdAndUp ? 'mx-4' : '',
          ]">
            <v-card-text :class="[additionalContentPadding ? 'px-4 pb-4' : 'px-0']">
              <slot name="additional-subview-content"></slot>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex v-if="showAdditionalContent">
          <v-layout align-center justify-end :class="['my-2', this.$vuetify.breakpoint.mdAndUp ? 'mx-2' : '']">
            <v-spacer></v-spacer>
            <slot name="additional-extra-buttons"></slot>
            <DefaultButton v-for="item in extraButtons" :key="item.id" :bgColor="item.color"
              @click.prevent="item.action">
              <v-icon v-if="item.icon" left small>{{ item.icon }}</v-icon>
              {{ item.name }}
            </DefaultButton>
          </v-layout>
        </v-flex>
        <v-flex v-if="showBottomContent">
          <v-card :class="[
            'default-shadow mt-6',
            this.$vuetify.breakpoint.mdAndUp ? 'mx-4' : '',
          ]">
            <v-card-text :class="[additionalContentPadding ? 'px-4 pb-4' : 'px-0']">
              <slot name="bottom-subview-content"></slot>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout class="status-message mx-0 my-4 text-center" align-center justify-center full-width>
        <v-spacer></v-spacer>
        <v-flex v-if="messageType != 0 && showMessage">
          <v-fade-transition>
            <Message :type="messageType" :text="message" @close="hideMessage"></Message>
          </v-fade-transition>
        </v-flex>
        <v-flex v-if="
          ($route.query.created || $route.query.deleted) && showCDMessage
            ? cdMessage
            : false
        ">
          <v-fade-transition>
            <Message :type="2" :text="cdMessage" @close="showCDMessage = false"></Message>
          </v-fade-transition>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";
import { mapGetters, mapActions } from "vuex";
import BooksyService from "@/services/booksy.service";

export default {
  timers: {
    hideCDMessage: { time: 5000, autostart: true },
  },
  mixins: [DoctorReloadMixin],
  props: {
    title: {
      type: String,
      default: ""
    },
    back: {
      type: Boolean,
      default: false
    },
    showMainContainer: {
      Type: Boolean,
      default: true,
    },
    loading: {
      default: false,
    },
    message: {
      default: "",
    },
    messageType: {},
    showMessage: {
      default: false,
    },
    buttons: {
      Type: Array,
      default: () => [],
      required: false,
    },
    extraButtons: {
      Type: Array,
      default: () => [],
      required: false,
    },
    createMessage: {},
    deleteMessage: {},
    today: {},
    showAdditionalContent: {
      type: Boolean,
      default: false,
    },
    additionalContentPadding: {
      type: Boolean,
      default: true,
    },
    contentPadding: {
      default: true,
    },
    showCurrentDoctor: {
      type: Boolean,
      default: false,
    },
    customCurrentDoctorMeText: {
      default: undefined,
    },
    showBottomContent: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    customContentPadding: {
      type: String,
      default: "px-0 pb-2"
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    hideShadow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCDMessage: true,
    };
  },
  components: {
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    Message: () => import("@/components/Message"),
  },
  computed: {
    ...mapGetters("ui", ["noticeHeaderVisible"]),
    cdMessage() {
      if (this.$route.query.created) {
        return this.createMessage;
      } else if (this.$route.query.deleted) {
        return this.deleteMessage;
      } else {
        return "";
      }
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    beforeLogout() {
      this.logout({ autologout: false, logoutFromAllDevices: false }).then(() =>
        this.$router.replace({
          name: "auth",
        })
      );
    },
    hideMessage() {
      this.$emit("hide");
    },
    hideCDMessage() {
      if (this.$route.query.created || this.$route.query.deleted) {
        //this.$router.replace(this.$route.path);
      }
    },
    reloadData() {
      this.$emit("refresh");
    },
    handleBack() {
      BooksyService.back();
    }
  },
};
</script>

<style lang="scss">
.zg-toolbar .v-toolbar__extension {
  padding: 0px !important;
}

.zg-toolbar .v-toolbar__content {
  padding-right: 0px !important;
}
</style>

<style scoped lang="scss">
.section-bg {
  height: 64px;
  box-shadow: 3px 0 8px -3px rgba(0, 0, 0, 0.1) !important;
  cursor: pointer;

  &.active {
    background-color: rgb(245, 250, 254);
  }

  &.min {
    min-width: 50px !important;
  }
}

.section-empty {
  height: 64px;
  width: 11px;
}

.shadow {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  z-index: 21 !important;
}

.czat-order {
  flex-wrap: wrap-reverse;
}

.absolute-container {
  position: absolute !important;
  height: calc(100% - 40px) !important;
  min-width: 100% !important;
}

.zg-menu-activator {
  margin-right: 6px;
  padding-right: 4px;
}

.status-message {
  position: fixed;
  top: 0px;
  z-index: 203 !important;
  width: 100% !important;
  pointer-events: none;
}
</style>
