<script>
import {mapState, mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters('calendar', ['getIsEventMoved']),
    },
    watch: {
       doctor: {
        handler () {
            if (!this.getIsEventMoved) {
                this.reloadData()
            }
        },
        deep: true
      } 
    }
}
</script>
