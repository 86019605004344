var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('v-container',{class:['px-6 py-0', _vm.fullHeight ? 'absolute-container' : 'container--fluid']},[_c('v-layout',{class:_vm.fullHeight ? 'fill-height' : '',attrs:{"column":""}},[(_vm.title)?_c('v-layout',{staticClass:"pb-6 mt-6"},[_c('v-layout',{staticStyle:{"min-height":"41px !important"},attrs:{"align-center":""}},[(_vm.back)?_c('v-flex',{staticStyle:{"cursor":"pointer","font-family":"b-icons","font-size":"20px","margin-right":"14px"},attrs:{"shrink":""},on:{"click":function($event){$event.preventDefault();return _vm.handleBack.apply(null, arguments)}}},[_vm._v("")]):_vm._e(),_c('DefaultTitle',[_vm._v(_vm._s(_vm.title))])],1)],1):_vm._e(),_c('v-layout',{class:[
        this.$vuetify.breakpoint.smAndUp ? '' : 'wrap czat-order',
        _vm.fullHeight ? 'fill-height' : '' ],attrs:{"justify-center":""}},[_c('v-flex',{class:_vm.fullHeight ? 'fill-height' : ''},[(_vm.showMainContainer)?_c('v-card',{class:[!_vm.hideShadow && 'default-shadow', _vm.fullHeight ? 'fill-height' : ''],style:({backgroundColor: _vm.backgroundColor})},[_c('v-card-text',{class:[
              _vm.fullHeight ? 'fill-height' : '',
              _vm.contentPadding ? '' : _vm.customContentPadding ]},[_vm._t("subview-content"),_c('v-layout',{class:[
                _vm.buttons.length && 'mt-2',
                this.$vuetify.breakpoint.mdAndUp ? 'mx-2' : '' ],attrs:{"align-center":"","justify-end":""}},[_vm._t("customButtons"),_vm._l((_vm.buttons),function(item){return _c('DefaultButton',{key:item.name,attrs:{"bgColor":item.color,"loading":item.loading},on:{"click":function($event){$event.preventDefault();return item.action.apply(null, arguments)}}},[_vm._v(_vm._s(item.name))])}),_vm._t("additional-buttons")],2)],2)],1):_vm._e(),_vm._t("subview-content-documents"),_vm._t("subview-content-legal")],2),_c('v-flex',{attrs:{"shrink":""}},[_vm._t("subview-right-content")],2)],1),_vm._t("middle-subview-content"),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdditionalContent),expression:"showAdditionalContent"}]},[_c('v-card',{class:[
          'default-shadow mt-6',
          this.$vuetify.breakpoint.mdAndUp ? 'mx-4' : '' ]},[_c('v-card-text',{class:[_vm.additionalContentPadding ? 'px-4 pb-4' : 'px-0']},[_vm._t("additional-subview-content")],2)],1)],1),(_vm.showAdditionalContent)?_c('v-flex',[_c('v-layout',{class:['my-2', this.$vuetify.breakpoint.mdAndUp ? 'mx-2' : ''],attrs:{"align-center":"","justify-end":""}},[_c('v-spacer'),_vm._t("additional-extra-buttons"),_vm._l((_vm.extraButtons),function(item){return _c('DefaultButton',{key:item.id,attrs:{"bgColor":item.color},on:{"click":function($event){$event.preventDefault();return item.action.apply(null, arguments)}}},[(item.icon)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)})],2)],1):_vm._e(),(_vm.showBottomContent)?_c('v-flex',[_c('v-card',{class:[
          'default-shadow mt-6',
          this.$vuetify.breakpoint.mdAndUp ? 'mx-4' : '' ]},[_c('v-card-text',{class:[_vm.additionalContentPadding ? 'px-4 pb-4' : 'px-0']},[_vm._t("bottom-subview-content")],2)],1)],1):_vm._e()],2),_c('v-layout',{staticClass:"status-message mx-0 my-4 text-center",attrs:{"align-center":"","justify-center":"","full-width":""}},[_c('v-spacer'),(_vm.messageType != 0 && _vm.showMessage)?_c('v-flex',[_c('v-fade-transition',[_c('Message',{attrs:{"type":_vm.messageType,"text":_vm.message},on:{"close":_vm.hideMessage}})],1)],1):_vm._e(),(
        (_vm.$route.query.created || _vm.$route.query.deleted) && _vm.showCDMessage
          ? _vm.cdMessage
          : false
      )?_c('v-flex',[_c('v-fade-transition',[_c('Message',{attrs:{"type":2,"text":_vm.cdMessage},on:{"close":function($event){_vm.showCDMessage = false}}})],1)],1):_vm._e(),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }